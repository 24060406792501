import { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Switch, Route, withRouter } from 'react-router-dom';

import Navigation from './components/Navigation';
import Articles from './pages/Articles';
import Login from './pages/Login';
import Register from './pages/Register';
import ViewArticle from './pages/ViewArticle';

import './App.css';

class App extends Component {
  REST_API = "https://api.advjsca2.clovux.net/api"
  constructor(props) {
    super(props);
    let localUser = null;
    const userString = localStorage.getItem("user");
    if (userString !== null) {
      localUser = JSON.parse(userString);
    }
    this.state = {
      user: localUser
    };
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
  };

  onLoginSuccess(loggedInUser, remember) {
    this.setState({
      user: loggedInUser
    });
    if (remember) {
      localStorage.setItem("user", JSON.stringify(loggedInUser));
    }
    this.props.history.push('/');
  }

  onLogoutSuccess(msg) {
    this.setState({
      user: null
    });
    localStorage.removeItem("user");
  }

  render() {
    return (
      <>
      <Navigation user={this.state.user} onLogoutSuccess={this.onLogoutSuccess} />

      <Container className="col-md-6 mb-3">
        <Switch>
          <Route exact path="/" render={(props) => (
            <Articles user={this.state.user}></Articles>
          )} />
          <Route exact path="/login">
            <Login onSuccess={this.onLoginSuccess} />
          </Route>
          <Route exact path="/register">
            <Register onSuccess={this.onLoginSuccess} />
          </Route>
          <Route exact path="/article/:id" render={(props) => (
            <ViewArticle user={this.state.user} />
          )} />
        </Switch>
      </Container>
      </>
    );
  }
}

export default withRouter(App);
