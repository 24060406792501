import { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

class Navigation extends Component {
  REST_API = "https://api.advjsca2.clovux.net/api";
  constructor(props) {
    super(props);
    this.onLogout = this.onLogout.bind(this);
  }

  onLogout(e) {
    fetch(this.REST_API + "/logout", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.props.user.api_token
      },
      body: ""
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.props.onLogoutSuccess(result.data);
        },
        (error) => {
          console.log(error);
          e.preventDefault();
        }
      )
  }

  render() {
    const user = this.props.user;

    return (
      <Navbar bg="primary" variant="dark" expand="lg">
        <Navbar.Brand as={Link} to="/">Articles</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">

          {user === null &&
            <Nav className="ml-auto">
              <Nav.Item >
                <Nav.Link as={Link} to="/login">Login</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/register">Register</Nav.Link>
              </Nav.Item>
            </Nav>
          }
          {user !== null &&
            <>
              <Nav className="ml-auto">
                <Nav.Item>
                  <Nav.Link as={Link} to="/login" onClick={this.onLogout}>Logout</Nav.Link>
                </Nav.Item>
              </Nav>
            </>
          }
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;
