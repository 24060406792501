import { Component } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import Filters from '../components/Filters';
import ArticleList from '../components/ArticleList';
import CreateArticleModal from '../components/CreateArticleModal';

class Articles extends Component {
  REST_API = "https://api.advjsca2.clovux.net/api";
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: [],
      selectedAuthor: null,
      showModal: false,
      setShow: false,
      articles: [],
      categories: [],
      error: null,
      editingArticle: false,
      loading: {
        page: false,
        create: false,
        delete: false
      },
      article: {
        title: "",
        body: "",
        category_id: null
      },
    };
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onAuthorChange = this.onAuthorChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.deleteArticleFromState = this.deleteArticleFromState.bind(this);
    this.editArticle = this.editArticle.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.resetArticleState = this.resetArticleState.bind(this);
    this.toggleLoadingState = this.toggleLoadingState.bind(this);
    this.sortArray = this.sortArray.bind(this);
    this.resetSelectedCategories = this.resetSelectedCategories.bind(this);
  }


  componentDidMount() {
    this.toggleLoadingState("page");
    fetch(this.REST_API + "/articles")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            articles: result,
          });
          this.toggleLoadingState("page");
        },
        (error) => {
          this.setState({
            error: error
          });
        }
      );
    fetch(this.REST_API + "/categories")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            categories: result
          });
        },
        (error) => {
          this.setState({
            error: error
          });
        }
      );
  }

  onCategoryChange(e) {
    const category = parseInt(e.target.value);

    //if target is already a selected category, remove it from selectedCategories
    if (this.state.selectedCategories.includes(category)) {
      this.setState({
        selectedCategories: this.state.selectedCategories.filter((c) => c !== category)
      });
    } else {
      this.setState({
        selectedCategories: [...this.state.selectedCategories, category]
      })
    }
  }

  onAuthorChange(e) {
    const author = e.target.value;
    this.setState({
      selectedAuthor: author
    });
  }

  toggleModal() {
    if (this.state.showModal) {
      this.setState({
        showModal: false
      })

      //delaying article reset after close due to modal animation
      setTimeout(
        () => {
          //delay editing state change due to modal animation
          if (this.state.editingArticle) {
            this.setState({ editingArticle: false });
          }
          this.resetArticleState();
        }, 80);

    } else {
      this.toggleLoadingState();
      this.setState({
        showModal: true
      })
    }
  }

  handleChange(e) {
    const target = e.target;
    const field = target.name;
    const value = target.value;
    const article = { ...this.state.article, [field]: value }

    this.setState({
      article
    });
  }

  onCreate(e) {
    e.preventDefault();
    this.toggleLoadingState("create");
    const article = this.state.article;
    fetch(this.REST_API + "/articles", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + this.props.user.api_token
      },
      body: JSON.stringify(article)
    })
      .then(res => res.json())
      .then(
        (result) => {
          fetch(this.REST_API + "/articles/" + result.id, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
              'Authorization': 'Bearer ' + this.props.user.api_token
            }
          })
            .then(res => res.json())
            .then(
              (result2) => {
                this.toggleModal();
                this.setState({
                  articles: [...this.state.articles, result2]
                })
                this.toggleLoadingState("create");
              },
              (error) => {
                console.log(error)
              }
            )
        },
        (error) => {
          console.log(error);
        }
      )
  }

  deleteArticleFromState(id) {
    let articles = this.state.articles;

    this.setState({
      articles: articles.filter((article) => article.id !== id),
    });
  }

  editArticle(article) {
    this.setState({
      editingArticle: true,
      article: article
    })
  }

  resetArticleState() {
    this.setState({
      article: {
        title: "",
        body: "",
        category_id: null
      }
    })
  }

  onEdit(e) {
    e.preventDefault();
    this.toggleLoadingState("create");
    const article = this.state.article;
    fetch(this.REST_API + "/articles/" + article.id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + this.props.user.api_token
      },
      body: JSON.stringify(article)
    })
      .then(res => res.json())
      .then(
        (result) => {
          //replace article in state with new article
          const articles = this.state.articles;
          let i = articles.findIndex((a) => a.id === article.id); //find article in state whose index matches the article id
          articles[i] = article;

          //get new category info if category changed
          if (article.category.id !== article.category_id) {
            const categories = this.state.categories;
            const newCategory = categories.find((c) => c.id == article.category_id); //find details of new category
            articles[i].category = newCategory;
          }
          this.setState({
            articles
          });
          this.toggleModal();
          this.resetArticleState();
          this.toggleLoadingState("create");
        },
        (error) => {
          console.log(error);
        }
      )
  }

  toggleLoadingState(type) {
    if (this.state.loading[type]) {
      var loading = { ...this.state.loading, [type]: false }
    } else {
      var loading = { ...this.state.loading, [type]: true }
    }
    this.setState({
      loading
    })
  }

  sortArray(array) {
    return array.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  }

  resetSelectedCategories() {
    this.setState({
      selectedCategories: []
    })
  }

  render() {
    return (
      <>
        {this.props.user !== null &&
          <div style={{ textAlign: "right", marginTop: 8 }}>
            <Button onClick={this.toggleModal}>Create Article</Button>
          </div>
        }

        <Filters categories={this.state.categories} onCategoryChange={this.onCategoryChange} onAuthorChange={this.onAuthorChange} resetSelectedCategories={this.resetSelectedCategories} selectedCategories={this.state.selectedCategories} />
        { this.state.loading.page
          ? <div className="text-center">
            <Spinner className="text-center mt-3" animation="border" />
          </div>
          : <ArticleList sortArray={this.sortArray} loadingDelete={this.state.loading.delete} toggleLoadingState={this.toggleLoadingState} toggleModal={this.toggleModal} editArticle={this.editArticle} deleteArticleFromState={this.deleteArticleFromState} user={this.props.user} articles={this.state.articles} selectedCategories={this.state.selectedCategories} selectedAuthor={this.state.selectedAuthor} />
        }
        <CreateArticleModal
          loading={this.state.loading.create}
          editingArticle={this.state.editingArticle}
          categories={this.state.categories}
          article={this.state.article}
          handleChange={this.handleChange}
          onCreate={this.onCreate}
          onEdit={this.onEdit}
          show={this.state.showModal}
          handleClose={this.toggleModal}
        />
      </>
    );
  }
}

export default withRouter(Articles);
