import { Component } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';

class CreateArticleModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const show = this.props.show;
    const handleClose = this.props.handleClose;
    const handleChange = this.props.handleChange;
    const article = this.props.article;
    const onCreate = this.props.onCreate;
    const onEdit = this.props.onEdit;
    const editing = this.props.editingArticle;
    const loading = this.props.loading;

    return (
      <Modal
        size="lg"
        show={show} //should it display - true or false
        onHide={handleClose} //function to run to hide the modal
        aria-labelledby="create-article-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="create-article-modal">
            {editing
              ? <>Edit </>
              : <>Create New </>
            }
            Article
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editing ? onEdit : onCreate}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control required name="title" type="text" value={article.title} onChange={handleChange} ></Form.Control>
            </Form.Group>
            <Form.Group controlId="body">
              <Form.Label>Body</Form.Label>
              <Form.Control required name="body" as="textarea" rows="6" value={article.body} onChange={handleChange} ></Form.Control>
            </Form.Group>
            <Form.Group controlId="category_id">
              <Form.Label>Category</Form.Label>
              <Form.Control required name="category_id" as="select" onChange={handleChange}>
                <option value="" disabled selected={article.category_id == null}>Select a category</option>
                {this.props.categories.map(category => (
                  <option key={category.id} value={category.id} selected={article.category_id == category.id}>{category.title}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button disabled={loading} variant="primary" type="submit">
              {loading
                ? <>{editing
                  ? 'Editing'
                  : 'Creating'}
                  ... <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></>
                : [
                  (editing
                    ? 'Edit'
                    : 'Create')
                ]
              }

            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CreateArticleModal;
