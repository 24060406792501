import { Component } from 'react';
import { Card, Badge, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class ArticleList extends Component {
  REST_API = "https://api.advjsca2.clovux.net/api";
  constructor(props) {
    super(props);
    this.state = {
      deletingId: null
    }
    this.deleteArticle = this.deleteArticle.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  deleteArticle(id, e) {
    //prevent all delete buttons from changing to loading
    this.setState({
      deletingId: id
    })
    e.preventDefault();
    this.props.toggleLoadingState("delete");
    fetch(this.REST_API + "/articles/" + id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + this.props.user.api_token
      },
    })
      .then(
        (result) => {
          if (result.status === 204) {
            //success
            this.props.deleteArticleFromState(id);
          } else {
            //fail
            //Todo: handle failure
          }
          this.props.toggleLoadingState("delete");
        },
        (error) => {
          this.props.toggleLoadingState("delete");
          console.log(error);
        }
      )
  }

  handleEditClick(article, e) {
    e.preventDefault();
    this.props.editArticle(article);
    this.props.toggleModal();
  }

  formatDate(date) {
    //declare the format for the date
    var options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      minute: 'numeric',
      hour: 'numeric',
    }
    return (Intl.DateTimeFormat('en-IE', options).format(new Date(date)));
  }

  render() {
    const articles = this.props.sortArray(this.props.articles);
    let selectedCategories = this.props.selectedCategories;
    let selectedAuthor = this.props.selectedAuthor;

    const filteredArticles = articles.filter(article =>
      (selectedCategories.length === 0 || selectedCategories.includes(article.category_id)) &&
      (selectedAuthor === null || article.user.name.includes(selectedAuthor))
    );

    return (
      filteredArticles.map((article) => (
        <Card key={article.id} as={Link} to={'article/' + article.id} style={{ color: 'inherit', textDecoration: 'inherit', marginTop: 16 }} >
          <Card.Body>
            <Card.Title>{article.title}</Card.Title>
            <Card.Text>{article.body}</Card.Text>
            <Card.Text className="mb-0 p-0" >
              <Badge pill variant="primary">
                {article.category.title}
              </Badge>
            </Card.Text>
            <Card.Text className="mt-0 pt-0" >
              <small className="text-muted">Posted by {article.user.name}</small>
              <small className="text-muted float-right">{this.formatDate(article.created_at)}</small>
            </Card.Text>
          </Card.Body>
          {this.props.user && (this.props.user.id === article.user.id) &&
            <Card.Footer>
              <div style={{ textAlign: "right" }}>
                <Button variant="primary" onClick={this.handleEditClick.bind(this, article)}>Edit</Button>{' '}
                <Button
                  disabled={this.props.loadingDelete && article.id === this.state.deletingId}
                  variant="danger"
                  onClick={this.deleteArticle.bind(this, article.id)}
                >
                  {this.props.loadingDelete && article.id === this.state.deletingId
                    ? <>
                        Deleting...
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      </>
                    : "Delete"
                  }
                </Button>
              </div>
            </Card.Footer>
          }
        </Card>
      ))
    );
  }
}

export default ArticleList;
