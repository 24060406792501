import { Component } from 'react';
import { Accordion, Button, Card, Col, Row, Form } from 'react-bootstrap';

class Filters extends Component {
  render() {
    const categories = this.props.categories;
    const onCategoryChange = this.props.onCategoryChange;
    const onAuthorChange = this.props.onAuthorChange;
    const selectedCategories = this.props.selectedCategories;
    const resetSelectedCategories = this.props.resetSelectedCategories;

    return (
      <Accordion className="mt-2">
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Filters
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Form onSubmit={e => { e.preventDefault(); }}>
                  <Form.Group as={Row}>
                    <Form.Label as="legend" column sm={6}>
                      Categories (<span style={{ color: "#007bff"}} onClick={resetSelectedCategories}>reset</span>)
                    </Form.Label>
                    <Col sm={10}>
                      {categories.map(category => (
                        <Form.Check key={category.id} type="checkbox" name="category" value={category.id} label={category.title} checked={selectedCategories.includes(category.id)} onChange={onCategoryChange} />
                      ))}
                    </Col>
                  </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Author
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control type="text" placeholder="Author" onChange={onAuthorChange} />
                  </Col>
                </Form.Group>
              </Form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

export default Filters;
