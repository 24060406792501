import { Component } from 'react';
import { Card, Spinner, Form, Button, Modal } from 'react-bootstrap';

class Comments extends Component {
  constructor(props) {
    super(props);
  };

  handleEditClick(comment, e) {
    e.preventDefault();
    this.props.handleEditComment(comment);
    this.props.toggleModal();
  }

  render() {
    const loadingState = this.props.loadingState;

    return (
      <>
        <Form onSubmit={this.props.onCommentSubmit}>
          <Form.Group controlId="body">
            <Form.Control required name="body" as="textarea" rows="4" value={this.props.comment.body} onChange={this.props.handleChange} ></Form.Control>
          </Form.Group>
          <Button disabled={loadingState.posting} variant="primary" type="submit">
            {loadingState.posting
              ? <>Posting...  <Spinner animation="border" size="sm" /></>
              : "Post Comment"
            }
          </Button>
        </Form>

        <div className="mt-5">
          {this.props.commentsLength === 0
            ? <p>There are no comments.</p>
            : this.props.sortComments(this.props.article.comments).map((comment) => (
              <Card key={comment.id} className="mt-3">
                <Card.Body>
                  <h6 className="mb-0">User {comment.user_id}</h6>
                  <small className="text-muted">{this.props.formatDate(comment.created_at)}</small>
                  <p className="mt-2 mb-0">{comment.body}</p>
                </Card.Body>
                {this.props.user.id === comment.user_id &&
                  <Card.Footer>
                    <div style={{ textAlign: "right" }}>
                      <Button variant="primary" onClick={this.handleEditClick.bind(this, comment)}>Edit</Button>{' '}
                      <Button
                        disabled={loadingState.deleting && comment.id === loadingState.deletingId}
                        variant="danger"
                        onClick={this.props.deleteComment.bind(this, comment.id)}
                      >
                        {loadingState.deleting && comment.id === loadingState.deletingId
                          ? <>Deleting... <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></>
                          : "Delete"
                        }
                      </Button>
                    </div>
                  </Card.Footer>
                }
              </Card>
              ))
          }
        </div>
        <Modal show={this.props.showModal} onHide={this.props.toggleModal} aria-labelledby="edit-comment-modal">
          <Modal.Header closeButton>
            <Modal.Title id="edit-comment-modal">Edit Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={(e) => { this.props.onEdit(e) }}>
              <Form.Group controlId="editComment">
                <Form.Control required name="body" as="textarea" rows="4" value={this.props.commentToEdit?.body} onChange={this.props.handleChange} ></Form.Control>
              </Form.Group>
              <Button disabled={loadingState.editing} variant="primary" type="submit">
                {loadingState.editing
                  ? <>Editing... <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></>
                  : 'Edit'
                }
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Comments;
