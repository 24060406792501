import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Badge, Spinner, Button } from 'react-bootstrap';

import Comments from '../components/Comments';

class ViewArticle extends Component {
  REST_API = "https://api.advjsca2.clovux.net/api";
  constructor(props) {
    super(props);
    this.state = {
      article: [],
      comment: {
        body: "",
        article_id: null
      },
      commentToEdit: null,
      error: null,
      loadingState: {
        loaded: false,
        posting: false,
        editing: false,
        deleting: false,
        deletingId: null
      },
      showModal: false
    }
    this.formatDate = this.formatDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCommentSubmit = this.onCommentSubmit.bind(this);
    this.sortComments = this.sortComments.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.handleEditComment = this.handleEditComment.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  };

  componentDidMount() {
    fetch(this.REST_API + "/articles/" + this.props.match.params.id)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            article: result,
            loadingState: {...this.state.loadingState, loaded: true},
            comment: { ...this.state.comment, article_id: this.props.match.params.id }
          });
        },
        (error) => {
          this.setState({
            error: error
          });
        }
      );
  }

  formatDate(date) {
    //declare the format for the date
    var options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      minute: 'numeric',
      hour: 'numeric',
    }
    return (Intl.DateTimeFormat('en-IE', options).format(new Date(date)));
  }

  handleChange(e) {
    //check if the form is for editing a comment or not
    if (e.target.id === "editComment") {
      const commentToEdit = { ...this.state.commentToEdit, body: e.target.value }
      this.setState({
        commentToEdit
      });
    } else {
      const comment = { ...this.state.comment, body: e.target.value }
      this.setState({
        comment
      });
    }

  }

  onCommentSubmit(e) {
    this.setState({
      loadingState: {...this.state.loadingState, posting: true}
    })
    e.preventDefault();
    //set article id
    this.setState({
      comment: { ...this.state.comment, article_id: this.props.match.params.id }
    })
    // this.toggleLoadingState("create");
    const comment = this.state.comment;
    fetch(this.REST_API + "/comments", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + this.props.user.api_token
      },
      body: JSON.stringify(comment)
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            article: { ...this.state.article, comments: [...this.state.article.comments, result] },
            comment: { ...this.state.comment, body: "" }
          })
          this.setState({
            loadingState: {...this.state.loadingState, posting: false}
          })
        },
        (error) => {
          console.log(error);
          this.setState({
            loadingState: {...this.state.loadingState, posting: false}
          })
        }
      )
  }

  sortComments(comments) {
    return comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  }

  deleteComment(id, e) {
    e.preventDefault();

    //prevent all delete buttons from changing to loading
    this.setState({
      loadingState: {
        ...this.state.loadingState,
        deletingId: id,
        deleting: true
      }
    })

    fetch(this.REST_API + "/comments/" + id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + this.props.user.api_token
      },
    })
      .then(
        (result) => {
          if (result.status === 204) {
            //success
            let comments = this.state.article.comments;
            this.setState({
              article: { ...this.state.article, comments: comments.filter((comment) => comment.id !== id) }
            });
            this.setState({
              loadingState: {...this.state.loadingState, deleting: false}
            })
          } else {
            //fail
            //Todo: handle failure
          }
          this.setState({
            loadingState: {...this.state.loadingState, deleting: false}
          })
        },
        (error) => {
          this.setState({
            loadingState: {...this.state.loadingState, deleting: false}
          })
          console.log(error);
        }
      )
  }

  resetCommentState(type) {
    if (type === "edit") {
      this.setState({
        commentToEdit: null
      })
    } else {
      this.setState({
        comment: null,
      })
    }
  }

  toggleModal() {
    if(this.state.showModal) {
      this.setState({
        showModal: false
      })

      // delaying article reset after close due to modal animation
      // setTimeout(
      //   () => {
      //     this.props.resetCommentState();
      //   }, 80);

    } else {
      // this.toggleLoadingState();
      this.setState({
        showModal: true
      })
    }
  }

  handleEditComment(comment) {
    this.setState({
      commentToEdit: comment
    })
  }

  onEdit(e) {
    e.preventDefault();
    this.setState({
      loadingState: {...this.state.loadingState, editing: true}
    })
    const commentToEdit = this.state.commentToEdit;
    fetch(this.REST_API + "/comments/" + commentToEdit.id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + this.props.user.api_token
      },
      body: JSON.stringify(commentToEdit)
    })
      .then(res => res.json())
      .then(
        (result) => {
          //replace comment in state with new comment
          const comments = this.state.article.comments;
          let i = comments.findIndex((c) => c.id === commentToEdit.id); //find comment in state whose index matches the edited comment id
          comments[i] = commentToEdit;

          this.setState({
            article: { ...this.state.article, comments: comments }
          });
          this.toggleModal();
          this.setState({
            loadingState: {...this.state.loadingState, editing: false}
          })
        },
        (error) => {
          console.log(error);
        }
      )
  }

  render() {
    const article = this.state.article;

    return (
      <>
        {/* using arrow function to prevent the function being called when rendered */}
        <Button className="mt-3 mb-3" onClick={() => this.props.history.push('/')}>&#129136; Back</Button>
        <Card>
          <Card.Body>
            {this.state.loadingState.loaded
              ? <>
                <h2 className="mb-0">{this.state.article.title}</h2>
                <small className="text-muted">Posted by {article.user.name} on {this.formatDate(article.created_at)}</small>
                <br />
                <span>
                  <Badge pill variant="primary">
                    {article.category.title}
                  </Badge>
                </span>
                <p className="mt-3">{article.body}</p>
              </>
              : <div className="text-center">
                <Spinner className="text-center" animation="border" />
              </div>
            }
          </Card.Body>
        </Card>
        {this.state.loadingState.loaded &&
          <>
            <h3 className="mt-4">Comments ({this.state.article.comments.length})</h3>

            {this.props.user
              ? <Comments
                  loadingState={this.state.loadingState}
                  commentsLength={this.state.article.comments.length}
                  user={this.props.user} article={this.state.article}
                  comment={this.state.comment} onCommentSubmit={this.onCommentSubmit}
                  handleChange={this.handleChange}
                  formatDate={this.formatDate}
                  sortComments={this.sortComments}
                  deleteComment={this.deleteComment}
                  handleEditComment={this.handleEditComment}
                  onEdit={this.onEdit}
                  toggleModal={this.toggleModal}
                  showModal={this.state.showModal}
                  commentToEdit={this.state.commentToEdit}
                />
              : <p>You need to be logged in to view comments!</p>
            }
          </>
        }
      </>
    );
  }
}

export default withRouter(ViewArticle);
